<template>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent" id="pageHeight">
    <div class="map_container" id="map_container">
      <img id="mapImage" :src="getImageUrl">
    </div>
        <h1 class="bgblueGray bigFont inline-block" id="hedaer1" style="margin-top:8vw;opacity:0">w 2019/20 r. nasiliła się krytyka IOC</h1>
        <h2 class="bgblueGray mediumFont inline-block" id="hedaer2" style="margin-top:80vh;opacity:0">spowodowana wysokimi kosztami organizacji igrzysk dla lokalnych społeczności oraz ignorowaniem praw człowieka przez gospodarzy igrzysk.</h2>
    <div class="links-wrapper">
      <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Locations from "@/components/dyscypliny/Locations.vue";
import PrevNextLinks from "@/components/PrevNextLinks.vue";
import Velocity from "velocity-animate";

const PageSzanse = defineComponent({
  name: "PageSzanse",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {
    //
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;

    Velocity(heder2 , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  }
})
export default PageSzanse;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
html,body{
  height:100%;
}
.links-wrapper{
  position:relative;
  z-index: 1200;
  left: 150px;
}
//.map_container{
//  width:100%;
//  height:100%;
//  position:absolute;
//  z-index:3;
//  top:0px;
//  left: 0px;
//  display:inline-block;
//  img{
//    max-width:100%;
//    max-height:100vh;
//  }
//}
//.pageContent{
//  background-color: #303030;
//  position:absolute;
//  top:0px;
//  width:calc(100vw - 80px);
//  //left:80px;
//  z-index: 8;
//  h1,h2,h3{
//    z-index: 300;
//    position:relative;
//  }
//  #mapImage{
//    position:absolute;
//    //width:100%;
//    left: 80px;
//  }
//}
.pageContent{
  position:absolute;
  z-index:31;
  background-color:#363b3d;
  width:calc(100% - 80px);
  height:99.9vh;
  overflow:hidden;
    h1,h2,h3{
      z-index: 300;
      position:relative;
    }
}
.arrow_down, .arrow_up{
  z-index:120;
}
.map_container{
  max-width:100%;
  max-height:100%;

  position:absolute;
  z-index:8;
  display:inline-block;
  img{
    max-width:100%;
    max-height:100vh;
  }

}
</style>
