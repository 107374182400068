<template>
  <div :class="cssClass" v-tippy="disciplineName" @click="onSelectDiscipline">
    <img :src="getSrcPath">
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {JKPresentation} from "@/JKPresentation/JKPresentation";
import { directive } from 'vue-tippy';
import {disciplines} from "./disciplines"

const discipline = defineComponent({
  directives: {
    tippy: directive,
  },
  name: "Discipline",
  props: {
    discipline: {
      type: String as  PropType<string>,
      required: true
    },
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  computed:{
    getSrcPath(): string {
      return 'assets/images/disciplines/' + this.discipline+'.svg';
    },
    cssClass(){
      let cssClassString = 'discipline'
      if(this.discipline === this.selectedDiscipline){
        cssClassString += ' active'
      }
      return cssClassString;
    },
    disciplineName(): object{
      return {content:disciplines[this.discipline].name}
    }
  },
  methods:{
    onSelectDiscipline(){
      this.$emit('onSelectDiscipline',this.discipline);
    }
  }
});
export default discipline;
</script>

<style scoped lang="scss">
.discipline{
  width:30px;
  background-color:RGBA(255,255,255,0.7);
  border:4px solid #fafafa;
  &.active{
    background-color:#81d5fc;
    border:4px solid #1b83da;
  }
  img{
    max-width:100%;
  }
}
</style>