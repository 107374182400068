


import {defineComponent,PropType} from "vue";
import {JKPresentation}  from "@/JKPresentation/JKPresentation"
import colorInlineStyle from "@/mixins/colorInlineStyle.vue"
const SectionHome = defineComponent({
  extends:colorInlineStyle,
  name: "noclegi",
  props: {
    page: {
      type: Object as PropType<JKPresentation.Page>,
      // type: JKPresentation.Page,
      required: true
    },
  },
})
export default SectionHome;
