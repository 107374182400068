<template>
  <div class="hello">
    section komunikacja

    <slot></slot>
  </div>
</template>

<script lang="ts">


import {defineComponent,PropType} from "vue";
import {JKPresentation}  from "@/JKPresentation/JKPresentation"
import colorInlineStyle from "@/mixins/colorInlineStyle.vue"
const SectionHome = defineComponent({
  extends: colorInlineStyle,
  name: "komunikacja",
  props: {
    color: {
      type: String,
      // type: JKPresentation.Page,
      required: true
    },
    // page: {
    //   type: Object as PropType<JKPresentation.Page>,
    //   // type: JKPresentation.Page,
    //   required: true
    // },
  },

})
export default SectionHome;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
