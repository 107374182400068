<template>
  <div class="sidebar">
    <SiebarElement v-for="(section, index) in getSectionList"
                   :key="index"
                   :section="section"
                   :currentcomponent="currentcomponent"
                   @change_page="$emit('change_page',$event)"
    ></SiebarElement>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import SiebarElement from "@/components/sidebar/SiebarElement.vue";
import {JKPresentation}  from "@/JKPresentation/JKPresentation";
// import { useStore } from '@/store/index'
import { key } from '@/store/index'
import { useStore } from 'vuex';
import {sections} from "@/routes";
const Sidebar = defineComponent({
// export default {
  name: "Sidebar",
  props: {
    currentcomponent:{
      type: String,
      required: true
    }
  },
  setup () {
    const store = useStore(key)
    return{
      store:store
    }
  },
  mounted(){
    this.store.commit('setSections',sections);
  },

  methods:{

  },
  computed:{
    getSectionList(): JKPresentation.SectionList{
      return this.store.state.sections;
    }
  },
  data(){
    // sectionList:
      return{
        sectionList:{} as JKPresentation.SectionList,
        test: {
          a:'a'
        }
    }
  },
  components: {SiebarElement}

});
export default Sidebar;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
