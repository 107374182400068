
declare interface BaseComponentData {
  locationListStore: JKPresentation.LocationList;
}

import Location from "@/components/dyscypliny/Location.vue";
import DisciplinesTable from "./DisciplinesTable.vue"
import {defineComponent, PropType} from "vue";
import {locationsData} from "./locations";
import SectionGeneral from "@/components/SectionGeneral.vue";
// import {PropType} from "vue";
import {JKPresentation} from "@/JKPresentation/JKPresentation";
const Locations = defineComponent({
  name: "Locations",
  props:{
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  components:{
    Location,DisciplinesTable
  },
  data(): BaseComponentData{
    return{
      locationListStore: []
    }

  },
  mounted(){
    this.locationListStore = locationsData;
  },
  methods:{
    onSelectDiscipline(payload: string){
      this.$emit('onSelectDiscipline',payload)
    }
  }
})

export default Locations;
