

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Locations from "@/components/dyscypliny/Locations.vue";
import PrevNextLinks from "@/components/PrevNextLinks.vue";
import Velocity from "velocity-animate";

const PageNoclegi = defineComponent({
  name: "PageNoclegi",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {
    //
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const list = document.querySelector("#list") as HTMLElement;
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
    Velocity(list , { opacity: 1, marginTop:'11vh' }, { delay: 1000, duration: 1800 });
  }
})
export default PageNoclegi;


