import {JKPresentation}  from "@/JKPresentation/JKPresentation"

const routes: JKPresentation.PageList = {
    '/':  ['PageIntro', 'historia', '/','/podsumowanie','/historia','sniezka_stanek_.jpg','2030/2034'],
    '/historia':  ['PageHistoria', 'historia', '/historia','/','/historia1','szklarska_poreba.jpg','Historia'],
    '/historia1': ['PageHistoria1', 'historia','/historia1','/historia','/historia2','garnish.jpg','Historia'],
    '/historia2': ['PageHistoria2', 'historia','/historia2','/historia1','/historia3','garnish_skocznia.jpg','Historia'],
    '/historia3': ['PageHistoria3', 'historia','/historia3','/historia2','/lokalizacja','solidarnosc.jpg','Współpraca'],
    '/lokalizacja': ['PageLokalizacja', 'lokalizacja', '/lokalizacja','/historia3','/harrahov','mapa_lokalizacja.jpg','Mapa'],
    '/harrahov': ['PageHarrahov', 'lokalizacja', '/harrahov','/lokalizacja','/jakuszyce','harrahov.jpg','Harrahov'],
    '/jakuszyce': ['PageJakuszyce', 'lokalizacja', '/jakuszyce','/harrahov','/jelenia','jakuszyce.jpg','Jakuszyce'],
    '/jelenia': ['PageJelenia', 'lokalizacja', '/jelenia','/jakuszyce','/liberec','jelenia_gora.jpg','Jelenia Góra'],
    '/liberec': ['PageLiberec', 'lokalizacja', '/liberec','/jelenia','/spindler','liberec.jpg','Liberec'],
    // '/borowice': ['PageBorowice', 'lokalizacja', '/borowice','/liberec','/spindler','harrahov.jpg','Borowice'],
    '/spindler': ['PageSpindler', 'lokalizacja', '/spindler','/liberec','/jablonec','spindler.jpg','Špindlerův Mlýn'],
    '/jablonec': ['PageJablonec', 'lokalizacja', '/jablonec','/spindler','/altenberg','jablonec.jpg','Jablonec nad Nisou'],
    '/altenberg': ['PageAltenberg', 'lokalizacja', '/altenberg','/jablonec','/pec','altenberg.jpg','Altenberg'],
    '/pec': ['PagePec', 'lokalizacja', '/pec','/altenberg','/karpacz','pec_mapa.jpg','Pec pod Sněžkou'],
    '/karpacz': ['PageKarpacz', 'lokalizacja', '/karpacz','/pec','/karpacz2','karpacz.jpg','Karpacz'],
    '/karpacz2': ['PageKarpacz2', 'lokalizacja', '/karpacz2','/karpacz','/upa','bobsleigh_karpacz.jpg','Karpacz'],
    '/upa': ['PageUpa', 'lokalizacja', '/upa','/karpacz2','/komunikacja','mala_upa.jpg','Mala Upa'],
    '/komunikacja': ['PageKomunikacja', 'komunikacja','/komunikacja','/upa','/koleje','lotniska.jpg','Lotniska'],
    '/koleje': ['PageKomunikacja', 'komunikacja','/koleje','/komunikacja','/drogi','koleje.jpg','Połączenia Kolejowe'],
    '/drogi': ['PageDrogi', 'komunikacja','/drogi','/koleje','/noclegi','drogi.jpg','Odległości Drogowe'],
    // '/noclegi': ['PageNoclegi', 'noclegi', '/noclegi', '/koleje','/szanse','noclegi.png','Noclegi'],
    // '/noclegi': ['PageNoclegi2', 'noclegi', '/noclegi', '/koleje','/szanse','karpaczcity.jpg','Noclegi'],
    '/noclegi': ['PageNoclegi', 'noclegi', '/noclegi', '/drogi','/szanse','sniezka_stanek1.jpg','Baza Noclegowa'],
    '/szanse': ['PageSzansePrasa', 'szanse', '/szanse','/noclegi','/agenda2020','agenda2000_news.jpg','Agenda 2020'],
    // '/agenda2020': ['PageSzanse', 'szanse', '/agenda2020','/szanse','/agenda2020_zalety','agenda2020cover.jpg','Agenda 2020'],
    '/agenda2020': ['PageSzanse', 'szanse', '/agenda2020','/szanse','/agenda2020_zalety','sniezka_stanek3.jpg','Agenda 2020'],
    '/agenda2020_zalety': ['PageSzanse2', 'szanse', '/agenda2020_zalety','/agenda2020','/rozpoczecie','karkonosze_kopa.jpg','Trwałość'],
    '/rozpoczecie': ['PageSzanseRozpoczecie', 'szanse', '/rozpoczecie','/agenda2020','/zakonczenie','wroclaw_wiki.jpg','Ceremonia Rozpoczęcia'],
    '/zakonczenie': ['PageSzanseZakonczenie', 'szanse', '/zakonczenie','/rozpoczecie','/kibice','edenarena_prague.jpg','Ceremonia Zakończenia'],
    '/kibice': ['PageSzanseKibice', 'szanse', '/kibice','/zakonczenie','/podsumowanie','kibice.jpg','Sport łączy'],
    '/podsumowanie': ['PageSzansePodsumowanie', 'szanse', '/podsumowanie','/kibice','/','podsumowanie.jpg','Podsumowanie'],
}

export const sections: JKPresentation.SectionList = {
    'historia' : ['#664d3b','#864E28','/','historia.svg','WPROWADZENIE','historia'],
    'lokalizacja' : ['#7f613d','#b8772a','/lokalizacja','dyscypliny.svg','LOKALIZACJA DYSCYPLIN','lokalizacja'],
    'komunikacja' : ['#3f5958','#386662','/komunikacja','komunikacja.svg','KOMUNIKACJA','komunikacja'],
    'noclegi' : ['#5a513d','#6E572B','/noclegi','noclegi.svg','NOCLEGI','noclegi'],
    'szanse' : ['#515c71','#5B6E94','/szanse','dyscypliny.svg','SZANSE','szanse'],
}


export default routes