

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";
import Locations from "@/components/dyscypliny/Locations.vue";
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageHarrahov = defineComponent({
  name: "PageHarrahov",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;
    
    Velocity(heder2 , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  }
})
export default PageHarrahov;


