<template>
  <div class="pageImageBackground" :style="getImageInlineStyle"></div>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent">
    <h1 class="bgblueGray bigFont inline-block" id="hedaer1" style="margin-top:8vw;opacity:0">Szklarska Poręba już raz ubiegała się</h1>
    <h1 class="bgblueGray bigFont inline-block" id="hedaer2" style="margin-top:80vh;opacity:0">o organizację Igrzysk Olimpijskich</h1>
    <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
  </div>
</template>

<script lang="ts">
import BasicPage from '@/components/pageTypes/BasicPage.vue';
import {defineComponent,PropType} from "vue";
import Velocity from 'velocity-animate';
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageHistoria = defineComponent({
  name: "PageHistoria",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;

    Velocity(heder2 , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  }
})
export default PageHistoria;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
