<template>
  <div class="pageImageBackground" :style="getImageInlineStyle"></div>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent" id="pageHeight">
<!--    <div class="map_container" id="map_container">-->
<!--      <img id="mapImage" :src="getImageUrl">-->
<!--    </div>-->
    <h1 class="bgblueGray bigFont inline-block" id="hedaer1" style="margin-top:8vw;opacity:0">Elementy Agendy 2021</h1>
    <ul class="bgblueGray inline-block" id="list" style="margin-top:80vh;opacity:0">
      <li>Nacisk na użycie istniejących obiektów</li>
      <li>Zmniejsznie kosztów aplikacji</li>
      <li>Trwałość - nacisk na trwałe pozytywne efekty ekonomiczne i społeczne igrzysk</li>
      <li>Ograniczenie liczby zawodników do 3000, personelu pomocniczego do 1500 os. i liczby imprez do 100</li>
      <li>Wioska olimpijska może być podzielona na różne miejscowości z wykorzystaniem ich istniejącej infrastruktury hotelowej</li>
    </ul>
    <div class="links-wrapper">
      <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Locations from "@/components/dyscypliny/Locations.vue";
import Velocity from "velocity-animate";
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageSzanse = defineComponent({
  name: "PageSzanse",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {
    //
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const list = document.querySelector("#list") as HTMLElement;
    //
    Velocity(list , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  }
})
export default PageSzanse;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#list{
  position:relative;
  z-index: 301;
  text-align:left;
  padding:20px 10px 20px 60px;
  min-width:80%;
  li{
    padding:8px 0px 8px 0px;
  }
  @media(min-width:1450px){
    font-size:1vw;
  }
}
html,body{
  height:100%;
}
.links-wrapper{
  position:relative;
  z-index: 1200;
  left: 150px;
}
.map_container{
  width:100%;
  height:100%;
  position:absolute;
  z-index:3;
  top:0px;
  left: 0px;
  display:inline-block;
  img{
    max-width:100%;
    max-height:100vh;
  }
}
.pageContent{
  //background-color: #303030;
  position:absolute;
  top:0px;
  width:calc(100vw - 80px);
  //left:80px;
  z-index: 8;
  h1{
    z-index: 300;
    position:relative;
  }
  #mapImage{
    position:absolute;
    //width:100%;
    left: 80px;
  }
}
</style>
