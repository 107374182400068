
import {defineComponent, PropType} from "vue";
// import {JKPresentation} from "@/JKPresentation/JKPresentation";
// import { directive } from 'vue-tippy';
import {disciplines} from "./disciplines"
import DisciplineListItem from './DisciplineListItem.vue';

const DisciplinesTable = defineComponent({
  name: "DisciplinesTable",
  components:{
    DisciplineListItem
  },
  props: {
    disciplineList: {
      type: String as  PropType<string>,
      required: true
    },
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  computed:{
    getDisciplines(){
      return disciplines
    }
  },
  methods:{
    onSelectDiscipline(discipline: string){
      this.$emit('onSelectDiscipline',discipline);
    }
  }
});
export default DisciplinesTable;
