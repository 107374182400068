
import {JKPresentation}  from "@/JKPresentation/JKPresentation"
export class PresentationPage {
    public PageFileName: string;
    public PageSiteSection: string;
    public PrevPageUrl: string;
    public NextPageUrl: string;
    public PageUrl: string;
    public ImageUrl: string;
    public PageTitle: string;


    public constructor(page: JKPresentation.Page) {
        console.log('#################page');
        console.log(page);
        this.PageFileName = page[0];
        this.PageSiteSection = page[1];
        this.PageUrl = page[2];
        this.PrevPageUrl = page[3];
        this.NextPageUrl = page[4];
        this.ImageUrl = page[5];
        this.PageTitle = page[6];


    }
    public getNextPageUrl() {
        // return pageList[this.NextPageUrl][2]
        return this.NextPageUrl;
    }
    public getPrevPageUrl() {
        return this.PrevPageUrl;
    }
    public getPageSectionName() {
        return this.PageSiteSection;
    }
    public getPageFileName(): string {
        return this.PageFileName;
    }
    public getImageUrl(): string {
        return 'assets/images/'+this.ImageUrl;
    }
    public getPageTitle(): string {
        return this.PageTitle;
    }
}



