<template>
  <div class="pageImageBackground" :style="getImageInlineStyle"></div>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent">
    <h1 class="bgblueGray bigFont inline-block" id="hedaer1" style="margin-top:8vw;opacity:0">Trasa saneczkowa - Bobsleigh i Luge</h1><br>
    <ul class="bgblueGray inline-block" id="list" style="margin-top:80vh;opacity:0">
      <li>Sztuczne naśnieżanie przy temperaturze do +20C</li>
      <li>Bobsleigh and skeleton	1413 m</li>
      <li>Luge - men's singles	1387 m</li>
      <li>Luge - women's singles	1220 m</li>
      <li> Luge - men's doubles, team relay and junior	996 m</li>
    </ul>
<!--    <h1 class="bgblueGray bigFont inline-block" id="hedaer2" style="margin-top:80vh;opacity:0">...</h1>-->
    <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
  </div>
</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageAltenberg = defineComponent({
  name: "PageAltenberg",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    // const heder2 = document.querySelector("#hedaer2") as HTMLElement;
    const list = document.querySelector("#list") as HTMLElement;
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
    Velocity(list , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
  }
})
export default PageAltenberg;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#list{
   position:relative;
   z-index: 301;
   text-align:left;
   padding:30px 30px 30px 60px;
   //min-width:80%;
   max-width:800px;
   li{
     padding:8px 0px 8px 0px;
   }
   @media(min-width:1450px){
     font-size:1vw;
   }
 }

</style>
