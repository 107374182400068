import {JKPresentation}  from "@/JKPresentation/JKPresentation"

export const locationsData: JKPresentation.LocationList = [
    {
        cityName:"Harrahov",
        disciplines:['nordic_combined','ski_jumping'],
        coordinates:[65,39],
        pageUrl: "/harrahov"
    },
    {
        cityName:"Jakuszyce",
        disciplines:['nordic_combined','biathlon','cross_country'],
        coordinates:[56,39],
        pageUrl:"/jakuszyce"
    },
    {
        cityName:"Jelenia Góra",
        disciplines:['curling','figure_skating','short_track'],
        coordinates:[38,70],
        pageUrl: "/jelenia"
    },
    {
        cityName:"Liberec",
        disciplines:['ice_hockey'],
        coordinates:[65,5],
        pageUrl: "/liberec"
    },
    {
        cityName:"Borowice",
        disciplines:['alpine'],
        coordinates:[60,64],
        pageUrl: "/borowice"
    },
    {
        cityName:"Špindlerův Mlýn",
        disciplines:['alpine'],
        coordinates:[76,55],
        pageUrl: "/spindler"
    },
    {
        cityName:"Jablonec nad Nisou",
        disciplines:['ice_hockey'],
        coordinates:[76,15],
        pageUrl: "/jablonec"
    },
    {
        cityName:"Altenberg",
        disciplines:['bobsleigh','skeleton'],
        coordinates:[20,15],
        pageUrl: "/altenberg"
    },
    {
        cityName:"Karpacz",
        disciplines:['bobsleigh','snowboard','luge','skeleton'],
        coordinates:[65,70],
        pageUrl: "/karpacz"
    },
    {
        cityName:"Mala Upa",
        disciplines:['snowboard'],
        coordinates:[78,73],
        pageUrl: "/upa"
    },
    {
        cityName:"Pec pod Sněžkou",
        disciplines:['snowboard','freestyle'],
        coordinates:[79,65],
        pageUrl: "/pec"
    }
]
