

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageHistoria2 = defineComponent({
  name: "PageHistoria2",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {
    const arrowBottom: HTMLElement = document.querySelector("#arrowBottom") as HTMLElement;
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;
    Velocity(arrowBottom , { transform: ["scale(1,1)","scale(0.1,0.1)"]}, { duration: 500 });
    Velocity(heder2 , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  }
})
export default PageHistoria2;


