
import {defineAsyncComponent,toRef,PropType,defineComponent} from "vue";
import {PresentationPage} from '@/JKPresentation/PresentationPage'
import {JKPresentation}  from "@/JKPresentation/JKPresentation"
import SectionGeneral from "@/components/SectionGeneral.vue";
import {sections} from "@/routes";
// import PageHome from '../pages/PageHome.vue';

import Sidebar from "@/components/sidebar/Sidebar.vue";
import {PresentationSection} from "@/JKPresentation/PresentationSection";
// import {PresentationSection} from "@/JKPresentation/PresentationSection";
// import HelloWorld from "@/components/HelloWorld.vue";
interface ComponentList {
  [index: string]: any; // Must accommodate all members
}
interface PageList {
  [index: string]: Promise<any>; // Must accommodate all members
}
const requireContext = require.context(
    "@/pages", //path to components folder which are resolved automatically
    true,
    /\.vue$/i,
    "sync"
);
const sectionsRequireContext = require.context(
    "@/sections", //path to components folder which are resolved automatically
    true,
    /\.vue$/i,
    "sync"
);
const componentNames = requireContext
    .keys()
    .map((file) => file.replace(/(^.\/)|(\.vue$)/g, ""));

const sectionNames = sectionsRequireContext
    .keys()
    .map((file) => file.replace(/(^.\/)|(\.vue$)/g, ""));

const pages: PageList = {};
// const pages = {};
const sectionList: ComponentList = {};

componentNames.forEach((component) => { //component represents the component name
  // pages[component] = defineAsyncComponent(() => //import each component dynamically
  //     import("@/pages/" + component + ".vue")
  // );
  // pages[component] = import("@/pages/" + component + ".vue");  //import each component dynamically
  console.log("@/pages/" + component + ".vue");
  pages[component] = defineAsyncComponent(() =>
        import("@/pages/" + component + ".vue")
  );
});

sectionNames.forEach((component) => { //component represents the component name
  sectionList[component] = defineAsyncComponent(() => //import each component dynamically
      import("@/sections/" + component + ".vue")
  );
});


console.log('sections');
console.log(sectionList);
console.log('pages');
console.log(pages);

const ViewController = defineComponent({
  name: "ViewController",
  props: {
    page: {
      type: Object as PropType<JKPresentation.Page>,
      // type: JKPresentation.Page,
      required: true
    }
  },
  components:{
    ...pages,
    ...sectionList,
    Sidebar,
    SectionGeneral
  },
  data(){
    return{
      pageName:"PageHome"
    }
  },
  methods:{
    newTest:function(){
      return "a";
    },
    getCurrentComp:function(){
      console.log(this);
      // return this.page.getPageFileName()
      return 'CurrentComponentSetup';
    },
    handleChangePage(payload: string){
      console.log('handleChangePage');
      console.log(payload);
      this.$emit('change_page',payload);
    }
  },
  mounted() {
    this.getCurrentComp();
    document.addEventListener("keydown",(evt: KeyboardEvent)=>{
      console.log(evt.code);
      let url = '';
      if(evt.code ==='ArrowUp' || evt.code === 'Backspace'){
          url = this.presentationPage.getPrevPageUrl();
      }
      if(evt.code ==='Space' || evt.code === 'ArrowDown'){
          url = this.presentationPage.getNextPageUrl();
      }
      if(url!==''){
        this.$emit('change_page',url);
        // if(window.location.protocol!=='file:'){
        //   window.location.href=url;
        // }
      }
    })
  },
  computed:{
    presentationPage(): PresentationPage{
     return  new PresentationPage(this.page);
    },
    presentationSection(): PresentationSection{
     return  new PresentationSection(sections[this.presentationPage.getPageSectionName()]);
    },

    getCurrentPage:function(): string{
      return this.presentationPage.getPageFileName()
    },
    getCurrentSection:function(): string{
      return this.presentationPage.getPageSectionName();
    },
    getCurrentSectionColor:function(): string{
      return this.presentationSection.getActiveColor();
    },
    getCurrentSectionLabel:function(): string{
      return this.presentationSection.getSectionHeader();
    },
  },

  setup(props){
    const pageRef = toRef(props, 'page');
    const PPage = new PresentationPage(pageRef.value)
    console.log('@/pages/'+ PPage.getPageFileName() +'.vue');
    console.log('internalInstance');
    // console.log(internalInstance);
    console.log('@/sections/'+ PPage.getPageSectionName() +'.vue');
    const CurrentSection = defineAsyncComponent(() =>
        import('@/sections/'+ PPage.getPageSectionName() +'.vue')
    )
    console.log(CurrentSection);
    return{
      CurrentSection,
      PPage
    }
  }
})



export default ViewController
