<template>
  <div :style="colorInlineStyle" class="topbar"></div>
  <div class="hello height100">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {defineComponent,PropType} from "vue";
import {JKPresentation}  from "@/JKPresentation/JKPresentation"
import colorInlineStyle from "@/mixins/colorInlineStyle.vue"

const SectionHome = defineComponent({
  extends:colorInlineStyle,
  name: "lokalizacja",
  props: {
    // color: {
    //   type: String,
    //   default: '#ccc'
    //   // type: JKPresentation.Page,
    //   // required: true
    // },
  },
})
export default SectionHome;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
