

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";
import Locations from "@/components/dyscypliny/Locations.vue"
import PrevNextLinks from "@/components/PrevNextLinks.vue"
const PageLokalizacja = defineComponent({
  name: "PageLokalizacja",
  extends: BasicPage,
  components: {Locations,PrevNextLinks},
  data(){
    return {
      marginTopStyle:{'margin-top':'0px'},
      marginTop:0,
      selectedDiscipline:'',
      docWidth: document.body.offsetWidth
    }
  },

  mounted() {
    // this.checkSize();
    window.onresize =this.checkSize;
    console.log( 'Document.getElementById(pageHeight).innerHeight');

    const heightMark = document.getElementById('pageHeight');
    this.marginTopStyle= {"margin-top":"0px"};
    // this.$nextTick(this.checkSize);
    document.onreadystatechange = () => {
      this.docWidth = document.body.offsetWidth;
      this.checkSize();
      // if (document.readyState == "complete") {
      //   console.log('Page completed with image and files!')
      //   // fetch to next page or some code
      // }
    };
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;
  },
  computed:{
    // docWidth(){
    //   const docElement = document.getElementById('pageHeight') as HTMLElement;
    //   return  docElement.offsetWidth;
    // },
    showMobile(): boolean{
      return this.docWidth<1100;
    }
  },
  methods:{
    checkSize(){
      this.docWidth = document.body.offsetWidth;
      // this.marginTopStyle = "margin-top:0px";
      const docElement = document.getElementById('pageHeight') as HTMLElement;
      const imageElement = document.getElementById('map_container') as HTMLElement;
      const mapImage = document.getElementById('mapImage') as HTMLElement;
      // imageElement. style.marginTop="0px";
      console.log(imageElement);
      console.log(imageElement.style);
      const imageHeight = imageElement.offsetHeight;
      const docHeight = docElement.offsetHeight;
      const docWidth = docElement.offsetWidth;
      const imageMapHeight = mapImage.offsetHeight;
      console.log('imageMapHeight');
      console.log(imageMapHeight);
      console.log('imageHeight');
      console.log(imageHeight);
      console.log('docHeight');
      console.log(docHeight);
      console.log(docHeight-imageHeight);
      if(this.docWidth>1550){
        this.marginTopStyle = {"margin-top":(docHeight - imageHeight) / 2 + 'px'};
      }else{
        this.marginTopStyle = {"margin-top":"30px"};
      }

    },
    onSelectDiscipline(payload: string){
      this.selectedDiscipline = payload;
    },
    // handleChangePage(url: string){
    //   this.$emit('change_page',url)
    // }
  }
})
export default PageLokalizacja;


