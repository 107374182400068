
import {JKPresentation}  from "@/JKPresentation/JKPresentation"
export class PresentationSection {
    public BaseColor: string;
    public ActiveColor: string;
    public BaseUrl: string;
    public SvgFileName: string;
    public SectionHeader: string;
    public SectionUniqueName: string;


    public constructor(section: JKPresentation.PSection) {
        this.BaseColor = section[0];
        this.ActiveColor = section[1];
        this.BaseUrl = section[2];
        this.SvgFileName = section[3];
        this.SectionHeader = section[4]
        this.SectionUniqueName = section[5]
    }

    public getBaseColor() {
        return this.BaseColor
    }
    public getActiveColor() {
        return this.ActiveColor
    }
    public getBaseUrl() {
        return this.BaseUrl
    }
    public getSvgFileName() {
        return 'assets/images/icons/'+this.SvgFileName;
    }
    public getSectionHeader() {
        return this.SectionHeader;
    }
    public getUniqueName() {
        return this.SectionUniqueName;
    }
}



