
import {defineComponent, PropType} from "vue";
import {PresentationPage} from "@/JKPresentation/PresentationPage";

const BasicPage  = defineComponent({
  name: "BasicPage",
  props: {
    pageData: {
      type: Object as PropType<PresentationPage>,
      // type: JKPresentation.Page,
      required: true
    },
  },
  computed:{
    getImageInlineStyle(): object{
      const imageUrl = this.pageData.getImageUrl()
      return {"background-image": 'url('+imageUrl+')','--background-image':imageUrl};
    },
    pageTitle(): string{
      return this.pageData.getPageTitle();
    },
    getImageUrl(): string{
      return this.pageData.getImageUrl();
    },
    nextPageUrl(): string{
      return this.pageData.getNextPageUrl();
    },
    prevPageUrl(): string{
      return this.pageData.getPrevPageUrl();
    }
  }
})
export default BasicPage;
