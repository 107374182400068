
import {defineComponent, PropType} from "vue";
import {JKPresentation}  from "@/JKPresentation/JKPresentation";
import {PresentationSection} from "@/JKPresentation/PresentationSection"
const SiebarElement = defineComponent({
    name: "SiebarElement",
    props: {
      section:{
        type: Object as PropType<JKPresentation.PSection>,
        required: true
      },
      isActive:{
        type: Boolean,
        required: true
      },
      currentcomponent:{
        type: String,
        required: true
      }
    },
    methods: {
      changePage(url: string){
        this.$emit('change_page',url);
        // if(window.location.protocol!=='file:'){
        //   window.location.href=url;
        // }
      }
    },
    computed:{
      presetationSection(): PresentationSection {
        return new PresentationSection(this.section)
      },
      sectionSvg(): string {
        return this.presetationSection.getSvgFileName();
      },
      sectionUrl(): string {
        return this.presetationSection.getBaseUrl();
      },
      cssClass(){
        let classString="section";
        if(this.currentcomponent === this.presetationSection.getUniqueName()){
          classString+= " active";
        }
        return classString;
      },
      inlineStyle(): object{
        const style = {"background-color":this.presetationSection.getBaseColor(),'--hover-color':this.presetationSection.getActiveColor()}
        if(this.isActive){
          style["background-color"] = this.presetationSection.getActiveColor();
        }
        return style;
      },

    }
  })
export default SiebarElement;
