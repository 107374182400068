
import {defineComponent, PropType} from "vue";
import {JKPresentation} from "@/JKPresentation/JKPresentation";
import { directive } from 'vue-tippy';
import {disciplines} from "./disciplines";
import Discipline from "./Discipline.vue"

const DisciplineListItem = defineComponent({
  directives: {
    tippy: directive,
  },
  components:{
    Discipline
  },
  name: "DisciplineListItem",
  props: {
    disciplineItem: {
      type: Object as  PropType<JKPresentation.Discipline>,
      required: true
    },
    idx: {
      type: Number as  PropType<number>,
      required: true
    },
    disciplineName: {
      type:  String as PropType<string>,
      required: true
    },
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  computed:{
    cssClass(){
      let cssClassString = 'disciplineRow'
      if(this.disciplineName === this.selectedDiscipline){
        cssClassString += ' active'
      }
      return cssClassString;
    },
    disciplineFullName(): object{
      return {content:this.disciplineName}
    }
  },
  methods:{
    onSelectDiscipline(){
      this.$emit('onSelectDiscipline',this.disciplineName);
    }
  }
});
export default DisciplineListItem;
