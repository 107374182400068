<template>
  <div :style="colorInlineStyle" class="topbar"></div>
  <div :style="colorInlineStyle" class="topbar_label">{{label}}</div>
  <div class="mainwrapper">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import colorInlineStyle from "@/mixins/colorInlineStyle.vue"
import {defineComponent} from "vue";

const SectionGeneral = defineComponent({
  extends: colorInlineStyle,
  name: "SectionGeneral",
  props: {
    label: {
      type: String
    }
  },

})
export default SectionGeneral;
</script>

<style scoped>

</style>