import { createApp } from 'vue'
import App from './App.vue'
// import {store} from "@/store";
import { store, key } from './store'
import VueTippy from 'vue-tippy'
// import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
// import {useStore}  from './store/index'
// import {key}  from './store/index'
// import {useStore}  from 'vuex'

// const store = useStore(key);
console.log('store')
console.log(store)
const app = createApp(App);
app.use(store,key);
app.use(VueTippy,{
    directive:'tippy',
    defaultProps: { placement: 'bottom' },
});
app.mount('#app');

