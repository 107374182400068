<template>
  <div class="pageImageBackground" :style="getImageInlineStyle"></div>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent" id="pageHeight">
    <div id="hedaer1" style="margin-top:8vw;opacity:0">
    <h1 class="bgblueGray mediumFont inline-block">Liczba miejsc noclegowych wg miejscowości</h1>
    </div>
    <ul class="bgblueGray inline-block" id="list" style="margin-top:80vh;opacity:0">
      <li>Karpacz ok. 25 000</li>
      <li>Jelenia Góra ok. 10 000</li>
      <li>Szklarska Poręba ok. 20 000</li>
      <li>Špindlerův Mlýn ok. 15 000</li>

    </ul>
    <!--    <h1 class="bgblueGray bigFont inline-block" id="hedaer1" style="margin-top:8vw;opacity:0">Skocznia mamucia</h1>-->
    <!--    <h1 class="bgblueGray bigFont inline-block" id="hedaer2" style="margin-top:80vh;opacity:0">z Garnisch Partenkirschen</h1>-->
    <div class="links-wrapper">
      <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Locations from "@/components/dyscypliny/Locations.vue";
import PrevNextLinks from "@/components/PrevNextLinks.vue";
import Velocity from "velocity-animate";

const PageNoclegi = defineComponent({
  name: "PageNoclegi",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {
    //
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const list = document.querySelector("#list") as HTMLElement;
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
    Velocity(list , { opacity: 1, marginTop:'11vh' }, { delay: 1000, duration: 1800 });
  }
})
export default PageNoclegi;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
html,body{
  height:100%;
}
.links-wrapper{
  position:relative;
  z-index: 1200;
  left: 150px;
}
.map_container{
  width:100%;
  height:100%;
  position:absolute;
  z-index:3;
  top:0px;
  left: 0px;
  display:inline-block;
  img{
    max-width:100%;
    max-height:100vh;
  }
}
.pageContent{
  //background-color: #303030;
  position:absolute;
  top:0px;
  width:calc(100vw - 80px);
  //left:80px;
  z-index: 8;
  h1{
    z-index: 300;
    position:relative;
  }
  #mapImage{
    position:absolute;
    //width:100%;
    left: 80px;
  }
}
#list{
  position:relative;
  z-index: 301;
  text-align:left;
  padding:30px 30px 30px 60px;
  //min-width:80%;
  max-width:800px;
  li{
    padding:8px 0px 8px 0px;
  }
  @media(min-width:1450px){
    font-size:1vw;
  }
}
</style>
