
// import { Options, Vue } from 'vue-class-component';
// import HelloWorld from './components/HelloWorld.vue';
import ViewController from './components/ViewController.vue';
import routes from './routes'

// import {JKPresentation.Page} from '@/JKPresentation'

import { defineComponent } from 'vue'
import {JKPresentation}  from "@/JKPresentation/JKPresentation"

const App = defineComponent({
  components:{
    ViewController
  },
  data: () => ({
    currentRoute: window.location.pathname,
    internalRoute:'/'
  }),
  props: {
    message: {
      type: String,
      required: true
    }
  },
  // setup() {
  //   // window.location.pathname.startsWith('/')
  //   // console.log('window')
  //   // console.log(window)
  //   // console.log(window.location.protocol)
  //   provide('isHttp', window.location.protocol!=='file:');
  // },
  methods : {
    getCurrentPage(){
      const matchingPage: JKPresentation.Page = routes [this.currentRouteValid] || routes ['404'];
      return matchingPage;
    },
    handleChangePage(payload: string){
      this.internalRoute = payload;
      // if(window.location.protocol!=='file:'){
      //   window.location.href=payload;
      // }
    }
  },
  computed:{
    CurrentPage (): JKPresentation.Page {
      console.log('this.currentRoute');
      console.log(this.currentRoute);
      return routes [this.currentRouteValid] || routes ['404'];
    },
    currentRouteValid(): string{
      // eslint-disable-next-line no-prototype-builtins
      return this.internalRoute;
      // if({}.hasOwnProperty.call(routes,this.currentRoute)){
      //   // return this.internalRoute;
      //   return this.currentRoute;
      // }else{
      //   return this.internalRoute;
      // }
    }
  },
  // render () {
  //   return h(this.ViewComponent)
  // },
  created () {

    window.addEventListener('popstate', () => {
      this.currentRoute = window.location.pathname
    })
  }
});

export default App
