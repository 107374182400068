

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Locations from "@/components/dyscypliny/Locations.vue";
import Velocity from "velocity-animate";
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageSzanse = defineComponent({
  name: "PageSzanse",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {
    //
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const list = document.querySelector("#list") as HTMLElement;
    //
    Velocity(list , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  }
})
export default PageSzanse;


