<template>
  <div :class="cssClass">
  <Discipline :key = "idx"
              :discipline = 'disciplineName'
              @onSelectDiscipline="onSelectDiscipline"
              :selectedDiscipline = 'selectedDiscipline'
  ></Discipline>
    <h3 @click="onSelectDiscipline">{{disciplineItem.name}}</h3>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {JKPresentation} from "@/JKPresentation/JKPresentation";
import { directive } from 'vue-tippy';
import {disciplines} from "./disciplines";
import Discipline from "./Discipline.vue"

const DisciplineListItem = defineComponent({
  directives: {
    tippy: directive,
  },
  components:{
    Discipline
  },
  name: "DisciplineListItem",
  props: {
    disciplineItem: {
      type: Object as  PropType<JKPresentation.Discipline>,
      required: true
    },
    idx: {
      type: Number as  PropType<number>,
      required: true
    },
    disciplineName: {
      type:  String as PropType<string>,
      required: true
    },
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  computed:{
    cssClass(){
      let cssClassString = 'disciplineRow'
      if(this.disciplineName === this.selectedDiscipline){
        cssClassString += ' active'
      }
      return cssClassString;
    },
    disciplineFullName(): object{
      return {content:this.disciplineName}
    }
  },
  methods:{
    onSelectDiscipline(){
      this.$emit('onSelectDiscipline',this.disciplineName);
    }
  }
});
export default DisciplineListItem;
</script>

<style scoped lang="scss">
.discipline{
  width:30px;
  background-color:RGBA(255,255,255,0.7);
  border:4px solid #fafafa;
  &.active{
    background-color:#81d5fc;
    border:4px solid #1b83da;
  }
  img{
    max-width:100%;
  }
}
.disciplineRow{
  display: flex;
  width: 25%;
  float: left;
  background-color: #fff;
  h3 {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 14px;
    font-size: 14px;
    margin-bottom: 0px;
    cursor: pointer;
    &:hover {
      color: #1b83da;
    }
  }
  &.active {
    color: #fff;
    background-color: #1b83da;

    h3 {
      &:hover {
        color: #eee;
      }
    }
  }
  .discipline {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;

  }
  @media (min-width:1200px) {
    width: 20%;
  }
  @media (min-width:1550px) {
    background-color: transparent;
    width: auto;
    float:none;
    display: flex;
    h3 {
      margin-left: 10px;
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      margin-top: 14px;
      margin-bottom: 0px;
    }

  }
}
</style>