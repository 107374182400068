<template>
  <div class="pageImageBackground" :style="getImageInlineStyle"></div>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent">
    <h1 class="bgblueGray bigFont inline-block" id="hedaer1" style="margin-top:8vw;opacity:0">Stadion Sinibo (Eden Aréna) w Pradze</h1>
    <h1 class="bgblueGray mediumFont inline-block" id="hedaer2" style="margin-top:80vh;opacity:0">Istniejący obiekt, 20 800 miejsc siedzących</h1>
    <h1 class="bgblueGray mediumFont inline-block" id="hedaer3" style="margin-top:80vh;opacity:0">Budowa stadionu na ceremonię rozpoczęcia i zakończenia stanowi znaczącą pozycję w kosztach igrzysk.</h1>
    <h1 class="bgblueGray mediumFont inline-block" id="hedaer4" style="margin-top:80vh;opacity:0">Te obiekty często są również problematyczne w utrzymaniu później.</h1>

    <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
  </div>
</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
// import Locations from "@/components/dyscypliny/Locations.vue";
import PrevNextLinks from "@/components/PrevNextLinks.vue";
import Velocity from "velocity-animate";

const PageSzanse = defineComponent({
  name: "PageSzanse",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;

    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
    Velocity(heder2 , { opacity: 1, marginTop:'8vh' }, { delay: 1000, duration: 1800 });
  }
})
export default PageSzanse;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#hedaer2{
  min-width:50%;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
