<template>
<!--  <div class="pageImageBackground map_location" :style="getImageInlineStyle"></div>-->
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent" id="pageHeight">


      <template v-if="!showMobile">
        <div class="map_container" id="map_container" :style="marginTopStyle">
        <img id="mapImage" src="assets/images/mapa_lokalizacja.jpg">
        <Locations
            @onSelectDiscipline="onSelectDiscipline"
            :selectedDiscipline = 'selectedDiscipline'
            @change_page="$emit('change_page',$event)"
        ></Locations>
        </div>
      </template>
      <template v-if="showMobile">
        <div class="mobileImageWrapper">
          <img id="mapImageMobile" src="assets/images/mapa_lokalizacja_mobile.jpg">
        </div>
      </template>
    </div>

  <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>

</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";
import Locations from "@/components/dyscypliny/Locations.vue"
import PrevNextLinks from "@/components/PrevNextLinks.vue"
const PageLokalizacja = defineComponent({
  name: "PageLokalizacja",
  extends: BasicPage,
  components: {Locations,PrevNextLinks},
  data(){
    return {
      marginTopStyle:{'margin-top':'0px'},
      marginTop:0,
      selectedDiscipline:'',
      docWidth: document.body.offsetWidth
    }
  },

  mounted() {
    // this.checkSize();
    window.onresize =this.checkSize;
    console.log( 'Document.getElementById(pageHeight).innerHeight');

    const heightMark = document.getElementById('pageHeight');
    this.marginTopStyle= {"margin-top":"0px"};
    // this.$nextTick(this.checkSize);
    document.onreadystatechange = () => {
      this.docWidth = document.body.offsetWidth;
      this.checkSize();
      // if (document.readyState == "complete") {
      //   console.log('Page completed with image and files!')
      //   // fetch to next page or some code
      // }
    };
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;
  },
  computed:{
    // docWidth(){
    //   const docElement = document.getElementById('pageHeight') as HTMLElement;
    //   return  docElement.offsetWidth;
    // },
    showMobile(): boolean{
      return this.docWidth<1100;
    }
  },
  methods:{
    checkSize(){
      this.docWidth = document.body.offsetWidth;
      // this.marginTopStyle = "margin-top:0px";
      const docElement = document.getElementById('pageHeight') as HTMLElement;
      const imageElement = document.getElementById('map_container') as HTMLElement;
      const mapImage = document.getElementById('mapImage') as HTMLElement;
      // imageElement. style.marginTop="0px";
      console.log(imageElement);
      console.log(imageElement.style);
      const imageHeight = imageElement.offsetHeight;
      const docHeight = docElement.offsetHeight;
      const docWidth = docElement.offsetWidth;
      const imageMapHeight = mapImage.offsetHeight;
      console.log('imageMapHeight');
      console.log(imageMapHeight);
      console.log('imageHeight');
      console.log(imageHeight);
      console.log('docHeight');
      console.log(docHeight);
      console.log(docHeight-imageHeight);
      if(this.docWidth>1550){
        this.marginTopStyle = {"margin-top":(docHeight - imageHeight) / 2 + 'px'};
      }else{
        this.marginTopStyle = {"margin-top":"30px"};
      }

    },
    onSelectDiscipline(payload: string){
      this.selectedDiscipline = payload;
    },
    // handleChangePage(url: string){
    //   this.$emit('change_page',url)
    // }
  }
})
export default PageLokalizacja;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#mapImageMobile{
  max-width: 100%;
}
.mobileImageWrapper{
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;

}
.pageContent{
  position:relative;
  z-index:31;
  background-color:#363b3d;
  width:calc(100% - 80px);
  height:100vh;
}
.arrow_down, .arrow_up{
  z-index:120;
}
.map_container{
  max-width:100%;
  max-height:100%;

  position:relative;
  z-index:30;
  display:inline-block;
  img{
    max-width:100%;
    max-height:100vh;
  }

}

</style>
