<template>
  <div class="disciplineListTable">
    <DisciplineListItem v-for="(discipline, idx) in getDisciplines"
                :key = "idx"

                :disciplineName = 'idx'
                :disciplineItem = 'discipline'
                @onSelectDiscipline="onSelectDiscipline"
                :selectedDiscipline = 'selectedDiscipline'
    ></DisciplineListItem>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
// import {JKPresentation} from "@/JKPresentation/JKPresentation";
// import { directive } from 'vue-tippy';
import {disciplines} from "./disciplines"
import DisciplineListItem from './DisciplineListItem.vue';

const DisciplinesTable = defineComponent({
  name: "DisciplinesTable",
  components:{
    DisciplineListItem
  },
  props: {
    disciplineList: {
      type: String as  PropType<string>,
      required: true
    },
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  computed:{
    getDisciplines(){
      return disciplines
    }
  },
  methods:{
    onSelectDiscipline(discipline: string){
      this.$emit('onSelectDiscipline',discipline);
    }
  }
});
export default DisciplinesTable;
</script>

<style scoped lang="scss">
.disciplineListTable{
  position:absolute;
  bottom: -150px;
  width:100%;
  background-color: RGBA(255,255,255,0.4);
  @media (min-width:1200px) {
    bottom: -100px;
  }
  @media (min-width:1550px){
    top:25%;
    left:82%;
    width:17%;
    bottom: auto;
    position:absolute;
  }
}
@media (min-width:1800px){
  .disciplineListTable{
    top:33%;
  }
}
</style>