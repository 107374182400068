import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
// import { createStore, useStore as baseUseStore, Store } from 'vuex'

// import '@/JKPresentation/JKPresentation'
import {JKPresentation} from "@/JKPresentation/JKPresentation";
// import SectionList = JKPresentation.SectionList;

// define your typings for the store state
export interface State {
  sections: JKPresentation.SectionList;
  activeSection: JKPresentation.SectionUniqueName;
  routes: JKPresentation.PageList;
  currentRoute: JKPresentation.SectionUniqueName;
}
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    sections: {},
    activeSection: 'home',
    routes: {},
    currentRoute: 'about'
  },
  mutations: {
    setSections(state,payload: JKPresentation.SectionList){
      state.sections = payload;
    },
    setRoutes(state,payload: JKPresentation.PageList){
      state.routes = payload;
    },
  },
  actions: {
  },
  modules: {

  }
})


// define your own `useStore` composition function
// export function useStore () {
//   console.log('usestore')
//   console.log(key)
//   return baseUseStore(key)
// }