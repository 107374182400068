
import {defineComponent, PropType} from "vue";
import SiebarElement from "@/components/sidebar/SiebarElement.vue";
import {JKPresentation}  from "@/JKPresentation/JKPresentation";
// import { useStore } from '@/store/index'
import { key } from '@/store/index'
import { useStore } from 'vuex';
import {sections} from "@/routes";
const Sidebar = defineComponent({
// export default {
  name: "Sidebar",
  props: {
    currentcomponent:{
      type: String,
      required: true
    }
  },
  setup () {
    const store = useStore(key)
    return{
      store:store
    }
  },
  mounted(){
    this.store.commit('setSections',sections);
  },

  methods:{

  },
  computed:{
    getSectionList(): JKPresentation.SectionList{
      return this.store.state.sections;
    }
  },
  data(){
    // sectionList:
      return{
        sectionList:{} as JKPresentation.SectionList,
        test: {
          a:'a'
        }
    }
  },
  components: {SiebarElement}

});
export default Sidebar;
