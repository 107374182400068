

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";

const PrevNextLinks = defineComponent({
  name: "PrevNextLinks",
  emits:['change_page'],
  props: {
    prevUrl: {
      type: String as  PropType<string>,
      required: true
    },
    nextUrl: {
      type:  String as PropType<string>,
      required: true
    }
  },
  methods:{
    changePage(url: string){
      this.$emit('change_page',url);

    }
  },
  mounted() {
    const arrowBottom: HTMLElement = document.querySelector("#arrowBottom") as HTMLElement;
    const arrowUp: HTMLElement = document.querySelector("#arrowUp") as HTMLElement;

    Velocity(arrowBottom , { transform: ["scale(1,1)","scale(0.1,0.1)"]}, { duration: 500 });
    Velocity(arrowUp , { transform: ["scale(1,1)  rotate(180deg)","scale(0.1,0.1)  rotate(180deg)"]}, { duration: 500 });
  }
})
export default PrevNextLinks;


