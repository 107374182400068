<template>
<div :style="getPostitionStyle" class="location">
  <h4 @click="openPage">{{getCity}}</h4>
  <div class="disciplines">
  <Discipline v-for="(discipline, idx) in getDisciplines"
              :key = "idx"
              :discipline = 'discipline'
              @onSelectDiscipline="onSelectDiscipline"
              :selectedDiscipline = 'selectedDiscipline'
  ></Discipline>
  </div>
</div>
</template>

<script lang="ts">
import Discipline from "@/components/dyscypliny/Discipline.vue"
import {defineComponent, PropType} from "vue";
import SectionGeneral from "@/components/SectionGeneral.vue";
import {JKPresentation} from "@/JKPresentation/JKPresentation";
// import {PropType} from "vue";

const Location = defineComponent({
  name: "Location",
  components: {Discipline},
  props:{
    location: {
      type:  Object as PropType<JKPresentation.Location>,
      required: true
    },
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  computed:{
    getCity(): string{
      return this.location.cityName;
    },
    getPostitionStyle(): object{
      return {
        top:this.location.coordinates[0]+'%',
        left:this.location.coordinates[1]+'%'
      }
    },
    getDisciplines(): Array<string> {
        return this.location.disciplines;
    }
  },
  methods:{
    onSelectDiscipline(payload: string){
      this.$emit('onSelectDiscipline',payload)
    },
    openPage(){
      this.$emit('change_page',this.location.pageUrl);
      // if(window.location.protocol!=='file:'){
      //   window.location.href=this.location.pageUrl;
      // }
    }
  }
})

export default Location;
</script>

<style scoped lang="scss">
h4{
  background-color: RGBA(2550,255,255,0.4);
  cursor:pointer;
  &:hover{
    background-color:#81d5fc;
  }
}
.location{
  position:absolute;
}
.disciplines{
  display:flex;
  justify-content: center;
}
h4{
  margin:0px;
}
</style>