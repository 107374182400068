<template>
  <div class="pageImageBackground" :style="getImageInlineStyle"></div>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent">
    <h1 class="bgblueGray bigFont inline-block" id="hedaer1" style="margin-top:0vw;opacity:0">Początki współpracy demokratycznej
      Polski i Czech w Karkonoszach
      sięgają czasów opozycji</h1>

    <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
  </div>
</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageHistoria2 = defineComponent({
  name: "PageHistoria2",
  components: {PrevNextLinks},
  extends: BasicPage,
  mounted() {
    const arrowBottom: HTMLElement = document.querySelector("#arrowBottom") as HTMLElement;
    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;
    Velocity(arrowBottom , { transform: ["scale(1,1)","scale(0.1,0.1)"]}, { duration: 500 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  }
})
export default PageHistoria2;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
