

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
// import Locations from "@/components/dyscypliny/Locations.vue";
import PrevNextLinks from "@/components/PrevNextLinks.vue";
import Velocity from "velocity-animate";

const PageSzanse = defineComponent({
  name: "PageSzansePodsumowanie",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;
    const heder3 = document.querySelector("#hedaer3") as HTMLElement;
    // const heder4 = document.querySelector("#hedaer4") as HTMLElement;

    Velocity(heder1 , { opacity: 1, marginTop:'15vh'}, { duration: 500 });
    Velocity(heder2 , { opacity: 1, marginTop:'7vh' }, { delay: 1000, duration: 1000 });
    Velocity(heder3 , { opacity: 1, marginTop:'7vh' }, { delay: 2000, duration: 1000 });
    // Velocity(heder4 , { opacity: 1, marginTop:'7vh' }, { delay: 3000, duration: 1000 });
  }
})
export default PageSzanse;


