
import {defineComponent,PropType} from "vue";
import {JKPresentation}  from "@/JKPresentation/JKPresentation"
import colorInlineStyle from "@/mixins/colorInlineStyle.vue"

const SectionHome = defineComponent({
  extends:colorInlineStyle,
  name: "lokalizacja",
  props: {
    // color: {
    //   type: String,
    //   default: '#ccc'
    //   // type: JKPresentation.Page,
    //   // required: true
    // },
  },
})
export default SectionHome;
