
import BasicPage from '@/components/pageTypes/BasicPage.vue';
import {defineComponent,PropType} from "vue";
import Velocity from 'velocity-animate';
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageHistoria = defineComponent({
  name: "PageHistoria",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;

    Velocity(heder2 , { opacity: 1, marginTop:'15vh' }, { delay: 1000, duration: 1800 });
    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
  },
  computed:{
    // introBackground(): any{
    //   return {"background-image": 'url(assets/images/intro_horizontal.svg)','--background-image':'assets/images/intro_horizontal.svg'};
    // }
  }
})
export default PageHistoria;

// return {"background-image": 'url('+imageUrl+')','--background-image':imageUrl};
