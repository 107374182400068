import {JKPresentation}  from "@/JKPresentation/JKPresentation"

export const disciplines: JKPresentation.Disciplines = {
    // eslint-disable-next-line @typescript-eslint/camelcase
   nordic_combined:{
       name:"Nordic Combined"
   },
    // eslint-disable-next-line @typescript-eslint/camelcase
   ski_jumping:{
       name:"Ski Jumping"
   },
   biathlon:{
       name:"Biathlon"
   },
    // eslint-disable-next-line @typescript-eslint/camelcase
   cross_country:{
       name:"Cross Country"
   },
    curling:{
        name:"Curling"
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    figure_skating:{
        name:"Figure Skating"
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    short_track:{
        name:"Short Track"
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    ice_hockey:{
        name:"Ice Hockey"
    },
    bobsleigh:{
        name:"Bobsleigh"
    },
    snowboard:{
        name:"Snowboard"
    },
    luge:{
        name:"Luge"
    },
    skeleton:{
        name:"Skeleton"
    },
    alpine:{
        name:"Alpine"
    },
    freestyle:{
        name:"Freestyle"
    }
   }
