<template>
    <a v-on:click="changePage(nextUrl)" class="arrow_down" id="arrowBottom" style="transform: scale(0.1, 0.1)"></a>
    <a v-on:click="changePage(prevUrl)" class="arrow_up" id="arrowUp" style="transform: scale(0.1, 0.1) rotate(180deg)"></a>

</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";

const PrevNextLinks = defineComponent({
  name: "PrevNextLinks",
  emits:['change_page'],
  props: {
    prevUrl: {
      type: String as  PropType<string>,
      required: true
    },
    nextUrl: {
      type:  String as PropType<string>,
      required: true
    }
  },
  methods:{
    changePage(url: string){
      this.$emit('change_page',url);

    }
  },
  mounted() {
    const arrowBottom: HTMLElement = document.querySelector("#arrowBottom") as HTMLElement;
    const arrowUp: HTMLElement = document.querySelector("#arrowUp") as HTMLElement;

    Velocity(arrowBottom , { transform: ["scale(1,1)","scale(0.1,0.1)"]}, { duration: 500 });
    Velocity(arrowUp , { transform: ["scale(1,1)  rotate(180deg)","scale(0.1,0.1)  rotate(180deg)"]}, { duration: 500 });
  }
})
export default PrevNextLinks;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  cursor:pointer;
}
</style>
