
import colorInlineStyle from "@/mixins/colorInlineStyle.vue"
import {defineComponent} from "vue";

const SectionGeneral = defineComponent({
  extends: colorInlineStyle,
  name: "SectionGeneral",
  props: {
    label: {
      type: String
    }
  },

})
export default SectionGeneral;
