
import {defineComponent, PropType} from "vue";

const colorInlineStyle = defineComponent({
  name:'colorInlineStyle',
  props: {
    color: {
      type: String,
      required: true,
      default: "#000000"
    },
  },
  computed:{
    colorInlineStyle(): object{
      return {"background-color":this.color,'--hover-color':this.color};
    }
  }
})
  export default colorInlineStyle;
