

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
import Velocity from "velocity-animate";
import PrevNextLinks from "@/components/PrevNextLinks.vue";

const PageSpindler = defineComponent({
  name: "PageSpindler",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;

    Velocity(heder1 , { opacity: 1, marginTop:'20vh'}, { duration: 500 });
    Velocity(heder2 , { opacity: 1, marginTop:'8vh' }, { delay: 1000, duration: 1800 });
  }
})
export default PageSpindler;


