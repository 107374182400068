<template>
<div class="">
  <Location v-for="(location,idx) in locationListStore"
            :key = "idx"
            :location = "location"
            @onSelectDiscipline="onSelectDiscipline"
            :selectedDiscipline = 'selectedDiscipline'
            @change_page="$emit('change_page',$event)"
  ></Location>
  <DisciplinesTable
      :selectedDiscipline = 'selectedDiscipline'
      @onSelectDiscipline="onSelectDiscipline"
  ></DisciplinesTable>
</div>
</template>

<script lang="ts">
declare interface BaseComponentData {
  locationListStore: JKPresentation.LocationList;
}

import Location from "@/components/dyscypliny/Location.vue";
import DisciplinesTable from "./DisciplinesTable.vue"
import {defineComponent, PropType} from "vue";
import {locationsData} from "./locations";
import SectionGeneral from "@/components/SectionGeneral.vue";
// import {PropType} from "vue";
import {JKPresentation} from "@/JKPresentation/JKPresentation";
const Locations = defineComponent({
  name: "Locations",
  props:{
    selectedDiscipline: {
      type:  String as PropType<string>,
      required: true
    }
  },
  components:{
    Location,DisciplinesTable
  },
  data(): BaseComponentData{
    return{
      locationListStore: []
    }

  },
  mounted(){
    this.locationListStore = locationsData;
  },
  methods:{
    onSelectDiscipline(payload: string){
      this.$emit('onSelectDiscipline',payload)
    }
  }
})

export default Locations;
</script>

<style scoped>

</style>