<template>
  <div class="pageImageBackground" :style="getImageInlineStyle"></div>
  <div class="pageName bigFont">{{pageTitle}}</div>
  <div class="pageContent">
    <h1 class="bgblueGray smallMediumFont inline-block" id="hedaer1" style="margin-top:8vw;opacity:0">Po igrzyskach zostaje nam baza szkoleniowa dla przyszłych pokoleń polskich sportowców</h1>
    <h2 class="bgblueGray smallMediumFont inline-block" id="hedaer2" style="margin-top:8vw;opacity:0">Wypromowana globalnie marka Polski i Karkonoszy <br> w dziedzinie sportów zimowych</h2>
    <h2 class="bgblueGray smallMediumFont inline-block" id="hedaer3" style="margin-top:8vw;opacity:0">Infrastruktura sportowa stworzy możliwości trenowania <br> dla dzieci i młodzieży - przyszłych olimpijczyków</h2>
    <h2 class="bgblueGray smallMediumFont inline-block" id="hedaer4" style="margin-top:8vw;opacity:0">Odrestaurowana infrastruktura drogowa i kolejowa <br>zwiększy atrakcyjność regionu dla turystów z Polski i innych państw</h2>
<!--    <h2 class="bgblueGray mediumFont inline-block" id="hedaer5" style="margin-top:8vw;opacity:0">Większość potrzebnych obiektów istnieje</h2>-->
<!--    <h2 class="bgblueGray mediumFont inline-block" id="hedaer6" style="margin-top:80vh;opacity:0">Część z nich wymaga modernizacji która przyniesie długofalowe pozytywne efekty dla regionu.<br>-->
<!--      Mogą one być później wykorzystane jako elementy infrastrutury turystycznej a także jako obiekty służące lokanej społecznośći.</h2>-->

<!--    <h2 class="bgblueGray mediumFont inline-block" id="hedaer2" style="margin-top:80vh;opacity:0">Część z nich wymaga modernizacji która przyniesie długofalowe pozytywne efekty dla regionu.<br>-->
<!--      Mogą one być później wykorzystane jako elementy infrastrutury turystycznej a także jako obiekty służące lokanej społecznośći.</h2>-->
    <PrevNextLinks @change_page="$emit('change_page',$event)" :prevUrl="prevPageUrl" :nextUrl="nextPageUrl"></PrevNextLinks>
  </div>
</template>

<script lang="ts">

import {defineComponent,PropType} from "vue";
import BasicPage from "@/components/pageTypes/BasicPage.vue";
// import Locations from "@/components/dyscypliny/Locations.vue";
import PrevNextLinks from "@/components/PrevNextLinks.vue";
import Velocity from "velocity-animate";

const PageSzanse = defineComponent({
  name: "PageSzanse",
  extends: BasicPage,
  components: {PrevNextLinks},
  mounted() {

    const heder1 = document.querySelector("#hedaer1")  as HTMLElement;
    const heder2 = document.querySelector("#hedaer2") as HTMLElement;
    const heder3 = document.querySelector("#hedaer3") as HTMLElement;
    const heder4 = document.querySelector("#hedaer4") as HTMLElement;

    Velocity(heder1 , { opacity: 1, marginTop:'12vh'}, { duration: 500 });
    Velocity(heder2 , { opacity: 1, marginTop:'7vh' }, { delay: 1000, duration: 1000 });
    Velocity(heder3 , { opacity: 1, marginTop:'7vh' }, { delay: 2000, duration: 1000 });
    Velocity(heder4 , { opacity: 1, marginTop:'7vh' }, { delay: 3000, duration: 1000 });
  }
})
export default PageSzanse;


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
